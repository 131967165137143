<template>
    <ion-page>
        <ion-header>
            <ion-toolbar>
                <ion-buttons slot="start">
                   <ion-button @click="$router.go(-1)">
                        <ion-img :src="require('@/assets/Icons/arrow-back-grey.svg')" class="w-6 h-6"></ion-img>
                    </ion-button>
                </ion-buttons>
                <ion-title><h1 class="text-base text-gray-400 text-center font-semibold">Settings</h1></ion-title>
                <ion-buttons slot="end">
                    <ion-button @click="openModal(id, 'tutorial')">
                        <ion-img :src="require('@/assets/Icons/help.svg')" class="w-6 h-6"></ion-img>
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>
        <ion-content :fullscreen="true" class="flex-col flex h-full">
            <ion-card class="w-72 mx-auto shadow-none">
                <ion-card-header>
                    <div class="rounded-full bg-smcc-smcc-green h-20 w-20 mx-auto mb-4 flex items-center justify-center">
                        <span class="text-white text-3xl font-bold uppercase" v-if="user.display_name">{{ getInitials(user.display_name) }}</span>
                    </div>
                    <ion-card-title class="text-center text-lmd font-bold text-smcc-smcc-grey" v-if="!user.display_name">Your Name</ion-card-title>
                    <ion-card-title class="text-center text-lmd font-bold text-smcc-smcc-grey">{{ user.display_name }}</ion-card-title>
<!--                    <ion-card-subtitle class="text-center text-xs normal-case underline mt-3 text-gray-300" :router-link="'/home/settings/edit'">Edit login settings</ion-card-subtitle>-->
                </ion-card-header>
            </ion-card>
            <ion-card class="mx-auto shadow-none">
                <ion-list>
                    <ion-item lines="none" class="border-t h-11 border-gray-100" :router-link="'/home/settings/swap-history'">
                        <p class="w-full h-11 -my-1 mb-0 flex justify-between items-center text-md text-smcc-grey-dark" fill="clear" expand="full">Swap History<ion-icon :src="require('@/assets/Icons/arrow-forward.svg')" class="text-gray-400"/></p>
                    </ion-item>
                    <ion-item lines="none" class="border-t h-11 border-gray-100" :router-link="'/home/settings/help-and-support'">
                        <p class="w-full h-11 -my-1 mb-0 flex justify-between items-center text-md text-smcc-grey-dark" fill="clear" expand="full">Help and Support<ion-icon :src="require('@/assets/Icons/arrow-forward.svg')" class="text-gray-400"/></p>
                    </ion-item>
                    <ion-item lines="none" class="border-t h-11 border-gray-100" :router-link="'/home/settings/about-us'">
                        <p class="w-full h-11 -my-1 mb-0 flex justify-between items-center text-md text-smcc-grey-dark" fill="clear" expand="full">About Us<ion-icon :src="require('@/assets/Icons/arrow-forward.svg')" class="text-gray-400" /></p>
                    </ion-item>
                    <ion-item lines="none" class="border-t h-11 border-gray-100" @click="openCapacitorSite('https://www.savemoneycutcarbon.com/privacy-and-cookie-policy/', access_token)">
                        <p class="w-full h-11 -my-1 mb-0 flex justify-between items-center text-md text-smcc-grey-dark" fill="clear" expand="full">Privacy Policy &amp; Terms of Service<ion-icon class="w-5 h-5 text-gray-400" :src="require('@/assets/Icons/external-link.svg')"/></p>
                    </ion-item>
                    <ion-item lines="none" class="border-b border-t h-11 border-gray-100">
                        <button @click="handleSignOut()" class="w-full h-11 -my-1 mb-0 flex justify-between items-center text-md text-smcc-grey-dark">Sign Out</button>
                    </ion-item>
                </ion-list>
            </ion-card>
            <div class="mx-auto text-sm text-gray-200 block text-center mt-40" @click="refresh()">App version 1.0.2</div>
        </ion-content>
    </ion-page>
</template>

<script>
import { IonPage, IonItem, IonList, IonHeader, IonTitle, IonToolbar, IonContent, IonButton, IonCard, IonCardHeader, IonCardTitle, IonButtons, modalController, IonImg, IonIcon } from '@ionic/vue';
import {TokenService} from "@/services/token.service";
import {useRouter} from 'vue-router';
import TutorialModal from '@/components/TutorialModal.vue';
import files from "@/data/filesystem";
import { Browser } from '@capacitor/browser';
import {mapActions} from "vuex";
import {mapGetters} from "vuex"
import store from "@/store";
import {get, set, remove} from '@/store/storage';

export default {
    name: 'ExploreContainer',
    props: {
        name: String
    },
    components: { IonPage, IonItem, IonList, IonHeader, IonTitle, IonToolbar, IonContent, IonButton, IonCard, IonCardHeader, IonCardTitle, IonButtons, IonImg, IonIcon },
    data() {
        const access_token = TokenService.getToken();
        return {
            user: [],
            access_token: access_token
        }
    },
    computed: {
        ...mapGetters("auth", [
            "authenticating",
            "authenticationError",
            "authenticationErrorCode"
        ])
    },
    setup() {
        const openModal = async (id, type) => {
        const modal = await modalController.create({
            component: TutorialModal, //Modal is name of the component to render inside ionic modal
            componentProps: {
                id: id,
                type: type
            },
            swipeToClose: true,
            backdropDismiss: true,
        });
            return modal.present();
        };
        const router = useRouter();
        return {
            router,
            openModal
        };
    },
    async created() {
        this.user = await this.getUser()
    },
    methods: {
        getInitials(str) {
            const matches = str.match(/\b(\w)/g);
            return matches.join('');
        },
        ...mapActions("auth", ["signOut", "refreshToken"]),
        async openCapacitorSite(url, access_token) {
            await Browser.open({ url: url+'?access_token='+access_token });
        },
        async refresh() {
            console.log('refreshing API and Token')
            await remove('declined_products');
            await remove('declined_swaps');
            await remove('accepted_products');
            await remove('last_action');
            await remove('tutorial');
            await files.getFiles(0);
            await this.refreshToken();
        },
        async handleSignOut() {
            await this.signOut().then(() => {
                this.router.push("/login");
            });
        },
          async getUser() {
              const user = await get('user_data');
              if (!user) {
                  const token = TokenService.getToken();
                  const url = 'https://www.savemoneycutcarbon.com?oauth=me&access_token=' + token;
                  let response = await fetch(url);

                  console.log(response)
                  if (response.ok) { // if HTTP-status is 200-299
                      // get the response body (the method explained below)
                      let json = await response;
                      await set('user_data', json);
                      console.log(json);
                      return json;
                  } else {
                      await store.dispatch("auth/signOut");
                      console.log("HTTP-Error: " + response.status);
                      return {}
                  }
                  //
              } else {
                  console.log(user)
                  return user;
              }
          }
    },

}
</script>

<style scoped>
#container {
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}

#container strong {
    font-size: 20px;
    line-height: 26px;
}

#container p {
    font-size: 16px;
    line-height: 22px;
    color: #8c8c8c;
    margin: 0;
}

#container a {
    text-decoration: none;
}

.button-inner{
    justify-content: space-between;
}
/*! purgecss start ignore */

/*! purgecss end ignore */
</style>
